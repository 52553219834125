<template>
  <div>
    <CRow>
      <CCol size="12">
        <CCard>
          <CCardHeader>
            <div class="header-div">
              <h5>Deals</h5>
              <!-- pipeline selector -->
              <CSelect
                label="Pipeline wechseln"
                :value="selectedPipeline"
                :options="pipelines"
                class="w-25"
                @update:value="selectedPipeline = $event"
              />
            </div>
          </CCardHeader>

          <CCardBody>
            <div
              id="pipeline"
              class="pipeline"
              style="min-width: 100%;"
              v-if="pipelines && pipelines.length > 0"
            >
              <div class="virtual-header"></div>
              <div
                class="step"
                v-for="(step, key) of selectedPipelineSteps"
                :key="key"
                :id="'step-' + key"
                v-on:mouseover="showedButton = step.id"
                v-on:mouseout="showedButton = null"
              >
                <div class="list-header text-center">
                  <div>
                    <h6>
                      {{step.name}}
                    </h6>
                    <span>
                      {{aggregateDealsOnStep(step) + '€'}} - {{formatPluralStr(step.deals, 'deal')}}
                    </span>
                  </div>
                  <svg class="arrow" width="16" height="56">
                    <g fill="none" fill-rule="evenodd">
                      <path class="arrow__right" fill="#F7F7F7" d="M0 0h16v56H0z"></path>
                      <path class="arrow__border" fill="#E5E5E5" d="M1 0l8 28-8 28H0V0z"></path>
                      <path class="arrow__left" fill="#F7F7F7" d="M0 1l8 27-8 27z"></path>
                    </g>
                  </svg>
                </div>
                <draggable
                  class="list-group"
                  :list="step.deals"
                  group="people"
                  :forceFallback="true"
                  @change="log"
                  :id="'step-body-' + key"
                  @start="dragging=true"
                  @end="dragging=false"
                  v-bind="{ghostClass:'ghost', dragClass: 'drag', chosenClass: 'chosen'}"
                  :class="{dragging: (showedButton === step.id) && dragging}"
                >
                  <div
                    class="list-group-item"
                    v-for="(element, index) in step.deals"
                    :key="element.name + '-' + index"
                    @click="showUpdateModal($event, element)"
                  >
                    <div class="deal-title">
                      <h6 class="deal-title">{{ element.name }}</h6>
                      <span class="ml-1">
                        {{element.realmPeople && element.realmPeople.name}}
                      </span>
                    </div>
                    <div class="deal-value">
                      <div class="deal-avatar">
                        <CIcon name="cil-user">
                        </CIcon>
                      </div>
                      <span class="ml-1_value">
                        {{element.value ? numberWithCommas(element.value) + '€' : ''}}
                      </span>
                    </div>
                    <CButton @click="showUpdateModal($event, element)" class="edit-icon text-primary">
                      <CIcon
                        name="cil-pencil"
                      />
                    </CButton>
                    <CButton @click="showDeleteModal($event, element)" class="delete-icon text-danger">
                      <CIcon
                        name="cil-trash"
                      />
                    </CButton>
                  </div>
                  <button slot="footer" v-show="showedButton === step.id" @click="showAddModal(step)">
                    <CIcon name="cil-plus"></CIcon>
                  </button>
                </draggable>
              </div>
            </div>
            <div v-else style="min-height: 60vh;">
              <h5>Bitte Pipeline hinzufügen, um Deal zu erstellen</h5>
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <!-- activity modal for a specific deal -->
    <CRow>
      <CCol md="12">
        <CModal
          title="Aktivität planen"
          size="lg"
          :show.sync="visibleActivityModal"
          :closeOnBackdrop="false"
        >
          <ActivityWidget
            ref="activityWidget"
            :applyUpdate="applyActivityUpdate"
            :closeModal="closeActivityModal"
          />
          <div slot="footer">
            <CButton
              class="mr-3"
              color="dark"
              variant="outline"
              @click="closeActivityModal()"
            >
              Abbrechen
            </CButton>
            <CButton
              color="success"
              v-on:click="submitActivity()"
            >
              Speichern
            </CButton>
          </div>
        </CModal>
      </CCol>
    </CRow>

    <!-- notes modal for a specific deal -->
    <CRow>
      <CCol md="12">
        <CModal
          title="Notizen"
          :show.sync="visibleNotesModal"
          :closeOnBackdrop="false"
        >
          <NotesWidget
            ref="notesWidget"
            :applyUpdate="applyNotesUpdate"
            :closeModal="closeNotesModal"
          />
          <div slot="footer">
            <CButton
              class="mr-3"
              color="dark"
              variant="outline"
              @click="closeNotesModal()"
            >
              Abbrechen
            </CButton>
            <CButton
              color="success"
              v-on:click="submitNotes()"
            >
              Speichern
            </CButton>
          </div>
        </CModal>
      </CCol>
    </CRow>

    <CRow>
      <CCol md="12">
        <CForm @submit.prevent="submit">
          <CModal
            :title="mode + ' deal'"
            :show.sync="visibleModal"
            :closeOnBackdrop="false"
            :size="mode === 'Edit' ? 'xl' : 'lg'"
          >
            <AddDealWidget
              :pipelines="pipelines"
              :originalPipelines="originalPipelines"
              :applyAdd="applyAdd"
              :applyUpdate="applyUpdate"
              :mode="mode"
              :show-create-activity-modal="showCreateActivityModal"
              :show-update-activity-modal="showUpdateActivityModal"
              :delete-activity="deleteActivity"
              :show-create-notes-modal="showCreateNotesModal"
              :show-update-notes-modal="showUpdateNotesModal"
              :delete-notes="deleteNotes"
              ref="dealWidget"
            />
            <div slot="footer">
              <CButton
                class="mr-3"
                color="dark"
                variant="outline"
                @click="visibleModal = false"
              >
                Abbrechen
              </CButton>
              <CButton
                color="success"
                type="submit"
              >
                Speichern
              </CButton>
            </div>
          </CModal>
        </CForm>
      </CCol>
    </CRow>
  </div>
</template>
<script>
  import draggable from "vuedraggable"
  import AddDealWidget from "./modal/AddDealWidget"
  import axios from "axios"
  import commons from "../../commons";
  import ActivityWidget from './modal/ActivityWidget'
  import moment from 'moment'
  import NotesWidget from "./modal/NotesWidget";

  export default {
    components: {
      NotesWidget,
      draggable,
      AddDealWidget,
      ActivityWidget,
    },
    async mounted() {
      await this.loadPipelines()
      this.selectedPipeline = this.pipelines && this.pipelines[0] && this.pipelines[0].value
    },
    data() {
      return {
        mode: 'add',
        visibleModal: false,
        showedButton: null,
        pipelines: [], // options to show on pipeline selector
        originalPipelines: [], // all pipelines
        selectedPipeline: null, // current selected pipeline id
        selectedPipelineSteps: [], // current selected pipeline steps
        previousPipelineSteps: [], // previous status of steps(used when drag and drop to change the step of deal)
        selectedDeal: null, // when click update deal, store selected deal
        movedDealId: null, // when drag and drop deal, store moved deal's id,
        dragging: false,
        // for scheduling the activity for a deal
        selectedTaskId: null,
        visibleActivityModal: false,
        // for writing notes for a deal
        selectedNotesId: null,
        visibleNotesModal: false,
      };
    },
    methods: {
      async loadPipelines() {
        const ret = await axios.get('/pipeline')
        this.originalPipelines = ret.data || []
        this.pipelines = []
        if (ret.data && ret.data.length > 0) {
          this.pipelines = ret.data.map(item => {
            return {
              value: item.id,
              label: item.name
            }
          })
        }
      },
      async loadPipelineDetail(id) {
        const ret = await axios.get(`/pipeline/${id}`)
        if (ret && ret.data) {
          this.selectedPipelineSteps = ret.data.pipelineSteps && ret.data.pipelineSteps.sort((a, b) => a.orderNumber - b.orderNumber)
          this.previousPipelineSteps = JSON.parse(JSON.stringify(this.selectedPipelineSteps))
        }
      },
      showAddModal(pipelineStep) {
        this.mode = 'Add'
        this.$refs.dealWidget.status = null
        this.$refs.dealWidget.selectedPipeline = this.selectedPipeline
        this.$refs.dealWidget.currentStep = pipelineStep
        this.$refs.dealWidget.name = ''
        this.$refs.dealWidget.value = ''
        this.$refs.dealWidget.selectedPeople = {
          value: '',
          text: '',
        }
        this.$refs.dealWidget.selectedGroup = {
          value: '',
          text: '',
        }
        this.visibleModal = true
      },
      showUpdateModal(e, deal) {
        if (e) {
          e.preventDefault();
          e.stopPropagation();
        }

        this.selectedDeal = deal
        const id = deal.id
        axios.get(`/deal/${id}`)
          .then(response => {
            const dealDetail = response.data

            this.$refs.dealWidget.selectedPeople = {
              value: (dealDetail.realmPeople && dealDetail.realmPeople.id) || '',
              text: (dealDetail.realmPeople && dealDetail.realmPeople.name) || '',
            }

            setTimeout(() => {
              // because deal group and deal name is related to change of deal people
              this.$refs.dealWidget.selectedGroup = {
                value: (dealDetail.realmGroup && dealDetail.realmGroup.id) || '',
                text: (dealDetail.realmGroup && dealDetail.realmGroup.title) || '',
              }

              this.$refs.dealWidget.name = dealDetail.name
            }, 100)
            this.$refs.dealWidget.value = dealDetail.value
            this.$refs.dealWidget.selectedPipeline = dealDetail.pipeline && dealDetail.pipeline.id
            this.$refs.dealWidget.currentStep = dealDetail.pipelineStep

            this.$refs.dealWidget.selectedDealId = deal.id
            this.$refs.dealWidget.status = dealDetail.status
            this.visibleModal = true
            this.mode = 'Edit'
          })
          .catch(err => {
            console.log(err)
          })
          .finally(() => {

          })
        // get activities for selected deal
        axios.get(`task/deal/${id}`)
          .then(response => {
            let content = []

            response.data.sort((a, b) => {
              const i = a.done ? 1 : 0;
              const j = b.done ? 1 : 0;

              if ((j - i) === 0) {
                return (new Date(a.startTime).getTime() - new Date(b.startTime).getTime())
              } else {
                return j - i;
              }
            })

            response.data.forEach(value => {
              value['startTimeObj'] = commons.formatStandardDate(new Date(value['startTime']))
              value['endTimeObj'] = commons.formatStandardDate(new Date(value['endTime']))
              value['date'] = new Date(value['startTime']).getTime()
              moment.locale('de')
              value['startTime'] = moment(value['startTime']).format('LLL')
              value['endTime'] = moment(value['endTime']).format('LLL')
              value['doneStr'] = value['done'] ? 'Yes' : 'No'
              value['category'] = 'activity'
              content.push(commons.flatObject(value))
            })
            this.$refs.dealWidget.activities = content
          })
          .catch(err => {
            console.log(err)
          })
        // get notes for selected deal
        axios.get(`notes/deal/${id}`)
          .then(response => {
            let content = []

            response.data.forEach(value => {
              value['category'] = 'notes'
              value['date'] = new Date(value['created']).getTime()
              content.push(value)
            })
            this.$refs.dealWidget.notesList = content
          })
          .catch(err => {
            console.log(err)
          })
      },
      async showDeleteModal(e, deal) {
        e.preventDefault();
        e.stopPropagation();

        const confirm = window.confirm('Sind Sie sicher, dass Sie diesen Deal löschen möchten?')
        if (confirm) {
          axios.delete(`/deal/${deal.id}`)
            .then(async () => {
              await this.loadPipelineDetail(this.selectedPipeline)
            })
            .catch(err => {
              console.log(err)
            })
            .finally(() => {

            })
        }
      },
      submit() {
        this.$refs.dealWidget.submit()
      },
      async applyAdd(deal) {
        // if watch selectedPipeline will not trigger
        if (this.selectedPipeline !== (deal && deal.pipeline && deal.pipeline.id)) {
          this.selectedPipeline = deal && deal.pipeline && deal.pipeline.id
        } else {
          await this.loadPipelineDetail(this.selectedPipeline)
        }

        // to apply changes on deals
        this.$refs.activityWidget.loadLeads()
        this.visibleModal = false
      },
      async applyUpdate(deal) {
        // if watch selectedPipeline will not trigger
        if (this.selectedPipeline !== (deal && deal.pipeline && deal.pipeline.id)) {
          this.selectedPipeline = deal && deal.pipeline && deal.pipeline.id
        } else {
          await this.loadPipelineDetail(this.selectedPipeline)
        }

        // to apply changes on deals
        this.$refs.activityWidget.loadLeads()
        this.visibleModal = false
      },
      async log(evt) {
        const keys = Object.keys(evt)
        if (keys && keys.length > 0) {
          this.movedDealId = evt[keys[0]].element && evt[keys[0]].element.id
          // compare previous and current steps to get moved deal's new step id
          for (let i = 0; i < this.previousPipelineSteps.length; i++) {
            const prevDealLen = this.previousPipelineSteps[i].deals && this.previousPipelineSteps[i].deals.length
            const newDealLen = this.selectedPipelineSteps[i].deals && this.selectedPipelineSteps[i].deals.length

            if (newDealLen > prevDealLen) {
              // change the step id of deal
              await axios.post(`/deal/change-step/${this.movedDealId}/${this.selectedPipelineSteps[i].id}`);
            }
          }

          this.previousPipelineSteps = JSON.parse(JSON.stringify(this.selectedPipelineSteps))
        }
      },
      aggregateDealsOnStep(step) {
        let sum = 0
        step && step.deals && step.deals.map(deal => {
          if (deal.value) {
            sum += deal.value
          }
        })

        return commons.numberWithCommas(sum)
      },
      formatPluralStr(arr, str) {
        if (arr && arr.length > 0) {
          if (arr.length > 1) {
            return `${arr.length} ${str}s`
          } else {
            return `1 ${str}`
          }
        } else {
          return `0 ${str}`
        }
      },
      numberWithCommas(num) {
        return commons.numberWithCommas(num)
      },
      // when creating activity for a specific deal
      showCreateActivityModal() {
        this.visibleActivityModal = true
        this.visibleModal = false
        this.selectedTaskId = null
        this.$refs.activityWidget.initialize(this.selectedDeal && this.selectedDeal.id)
      },
      // when updating activity for a specific deal
      showUpdateActivityModal(item) {
        this.$refs.activityWidget.initializeWithEntity(item)
        this.selectedTaskId = item.id
        this.visibleActivityModal = true
        this.visibleModal = false
      },
      // when deleting activity for a specific deal
      deleteActivity(item) {
        axios.delete(`task/${item.id}`)
          .then(() => {
            this.$refs.dealWidget.activities = this.$refs.dealWidget.activities.filter(entity => entity.id !== item.id)
          })
          .catch((err) => {
            console.log(err)
          })
          .finally(() => {
            this.tableShowLoading = false
          })
      },
      // after updating or creating a activity for a specific deal, show deal detail modal again
      applyActivityUpdate() {
        this.showUpdateModal(null, this.selectedDeal)
      },
      closeActivityModal() {
        this.$refs.activityWidget.initialize()
        this.visibleActivityModal = false
        this.showUpdateModal(null, this.selectedDeal)
      },
      // when click save button on activity modal
      submitActivity() {
        this.$refs.activityWidget.submit(this.selectedTaskId)
      },
      // after updating or creating a activity for a specific deal, show deal detail modal again
      applyNotesUpdate() {
        this.showUpdateModal(null, this.selectedDeal)
      },
      closeNotesModal() {
        this.$refs.notesWidget.initialize()
        this.visibleNotesModal = false
        this.showUpdateModal(null, this.selectedDeal)
      },
      // when click save button on notes modal
      submitNotes() {
        this.$refs.notesWidget.submit(this.selectedNotesId)
      },
      // when creating notes for a specific deal
      showCreateNotesModal() {
        this.visibleNotesModal = true
        this.visibleModal = false
        this.$refs.notesWidget.initialize(this.selectedDeal && this.selectedDeal.id)
      },
      // when updating notes for a specific deal
      showUpdateNotesModal(item) {
        this.$refs.notesWidget.initializeWithEntity(item)
        this.selectedNotesId = item.id
        this.visibleNotesModal = true
        this.visibleModal = false
      },
      // when deleting notes for a specific deal
      deleteNotes(item) {
        axios.delete(`notes/${item.id}`)
          .then(() => {
            this.$refs.dealWidget.notesList = this.$refs.dealWidget.notesList.filter(entity => entity.id !== item.id)
          })
          .catch((err) => {
            console.log(err)
          })
          .finally(() => {
            this.tableShowLoading = false
          })
      },
    },
    watch: {
      selectedPipelineSteps: {
        deep: true,
        handler(newVal) {

        }
      },
      selectedPipeline(val) {
        if (val) {
          this.loadPipelineDetail(val)
        }
      }
    }
  }
</script>

<style>
  .card {
    background-color: rgb(247, 247, 247);
  }

  .list-group {
    cursor: pointer;
    position: relative;
    min-height: 60vh;
    height: 100%;
  }

  .list-group > button {
    background-color: transparent;
    border: none;
    height: 30px;
    width: calc(100% - 20px);
    margin-left: 10px;
    outline: none;
  }

  .list-group > button:hover {
    background-color: rgba(38, 41, 44, .05);
  }

  .virtual-header {
    position: absolute;
    width: calc(100% - 50px);
    margin-left: 10px;
    height: 55px;
  }

  .list-header {
    position: relative;
    display: flex;
    background-color: #f7f7f7;
    justify-content: center;
    align-items: center;
    height: 55px;
    border-width: 1px 0 1px 1px;
    border-style: solid;
    border-color: #dfdfe2;
    margin-left: 10px;
  }

  .list-header svg {
    position: absolute;
    right: 0;
  }

  .list-header h6 {
    font-weight: 600 !important;
    font-size: 18px !important;
  }

  .list-group-item {
    width: calc(100% - 20px);
    margin-left: 10px;
    margin-bottom: 4px;
    z-index: 3;
    background-color: white;
    min-height: 130px;
  }

  .list-group-item:hover {
    box-shadow: 0 0 3px gray;
  }

  .list-group-item > .edit-icon {
    position: absolute;
    top: 30px;
    right: 25px;
    box-shadow: none;
  }

  .list-group-item .delete-icon {
    position: absolute;
    top: 30px;
    right: 0;
    box-shadow: none;
  }

  .list-group-item > .deal-title {
    width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .deal-title > h6 {
    font: 600 15px / 20px "Source Sans Pro", sans-serif;
    margin: 0;
  }

  .deal-title > span.ml-1 {
    font: 400 13px / 16px "Source Sans Pro", sans-serif;
    color: rgb(116, 118, 120);
    min-height: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 0px !important;
  }

  .deal-value {
    font: 600 13px / 16px "Source Sans Pro", sans-serif;
    color: rgb(116, 118, 120);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-top: 5px;
  }

  .deal-avatar {
    float: left;
  }

  .vuecal__flex {
    margin-bottom: 1px;
  }


  .header-div {
    display: flex;
    justify-content: space-between;
  }

  .pipeline {
    overflow-x: scroll;
    overflow-y: hidden;
    display: flex;
  }

  .step {
    width: 270px;
    min-width: 270px;
    padding-left: 0;
    padding-right: 0;
  }

  .drag {
    background-color: gray !important;
    opacity: 0.5;
  }

  .ghost {
    background-color: #f5bebe !important;
  }

  .chosen {
    background-color: gray;
    opacity: 0.5;
  }

  .dragging {
    background-color: #b2abf1;
  }
</style>
