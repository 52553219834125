<template>
  <CRow>
    <CCol md="12">
      <WysiwygEditor
        v-model="notes"
      />
    </CCol>
  </CRow>
</template>

<script>
  import axios from "axios"
  import WysiwygEditor from '@/components/WysiwygEditor'

  export default {
    components: {
      WysiwygEditor,
    },
    props: [
      'closeModal',
      'applyUpdate',
    ],
    data: () => ({
      notes: '',
      selectedDealId: null,
    }),
    methods: {
      submit(selectedNotesId) {
        const data = {
          notes: this.notes,
          deal: {
            id: this.selectedDealId,
          }
        }

        if (!selectedNotesId) { // when click create notes
          axios.post('/notes', data)
            .then((response) => {
              this.closeModal()
              this.applyUpdate(response.data)
            })
            .catch(err => {
              console.log(err)
            })
            .finally(() => {

            })
        } else { // when click update notes
          axios.put(`/notes/${selectedNotesId}`, data)
            .then((response) => {
              this.initialize()
              this.closeModal()
              this.applyUpdate(response.data)
            })
            .catch(err => {
              console.log(err)
            })
            .finally(() => {

            })
        }
      },
      initialize(selectedDealId = null) {
        this.selectedDealId = selectedDealId
        this.notes = ''
      },
      initializeWithEntity(entity) {
        this.notes = entity.notes
        this.selectedDealId = entity.deal && entity.deal.id
      },
    }
  }
</script>

<style>
  .tox-tinymce {
    min-height: 300px !important;
  }
</style>